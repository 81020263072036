import React from 'react';
import { graphql } from 'gatsby';

import { Page } from '../components/layout';

// import styles from './job.module.scss';

const Terms = ({
  data: {
    markdownRemark: {
      fields: {
        slug
      },
      html
    }
  }
}) => (
  <Page 
    slug={slug}
    body={html}
    showContact={false}>
  </Page>
);

export const termsQuery = graphql`
  query termsQuery($remarkId: String!) {
    markdownRemark(id: { eq: $remarkId }) {
      fields {
        slug
      }
      html
    }
  }
`;

export default Terms;